<template>
  <div>
    <Nav />
    <router-view :style="{minHeight:Height+'px'}" :key="$route.fullPath"></router-view>
    <Footer />
  </div>
</template>
<script>
import "@/assets/css/_reset.less";
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
// import {setFriendLinkData} from '@/utils/store-data'
import { getFriendLinkList } from '@/api/nav'
export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      isHome: true,
      Height: 0
    };
  },

  mounted() {
    //动态设置内容高度 让footer始终居底
    this.Height = document.documentElement.clientHeight - 402;
    //监听浏览器窗口变化
    window.onresize = ()=> {this.Height = document.documentElement.clientHeight -402}
    this.getFriedLink()
  },
  methods: {
    getFriedLink() {
      getFriendLinkList().then((res) => {
        if (res.data) {
          const list = res.data.map((item) => {
            return {
              id: item.id,
              url: item.url,
              title: item.title,
            }
          })
          this.$store.dispatch('setFriendLink', list)
        }
      })
    },
  }
};
</script>
<style>
html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
</style>
