<template>
  <div id="nav">
    <div class="top_nav">
      <router-link to="/" class="logo_content" @click.native="logoFn"></router-link>
      <div class="nav">
        <!-- {{catalogList}} -->
        <!-- <div :class="{'nav_item': true,'active': activeIndex == '/'}">
          <router-link to="/">
            <a class="nav_item_title">
              路演
              <i class="gsjs_arrow"></i>
            </a>
          </router-link>
        </div> -->
        <div v-for="(nav,index) in catalogList" :key="index" :class="{'nav_item': true,'active': (activeIndex == nav.id || (activeIndex== '/' && nav.name=='首页'))}">
          <template v-if="nav.catalogEntityList.length == 0">
            <router-link :to="redirectUrl(nav,'','')" @click.native="firstNavLink(nav)">
              <a class="nav_item_title">{{nav.name}}
                <i class="gsjs_arrow"></i>
              </a>
            </router-link>
          </template>
          <template v-else>
            <a class="nav_item_title">
              {{nav.name}}
              <i class="gsjs_arrow"></i>
            </a>
          </template>

          <div class="gsjs_move" v-if="nav.catalogEntityList && nav.catalogEntityList.length">
            <div style="padding-top:8px;">
              <div class="gsjs_content gsjs_content_nochild">
                <div class="gsjs_item" v-for="(secNav,index) in nav.catalogEntityList" :key="index" :style="{width: secNav.name.length>10 ? '226px': '218px'}">
                  <template v-if="secNav.catalogEntityList.length == 0">
                    <router-link :to="redirectUrl(nav, secNav,'')" @click.native="flushCom(nav,redirectUrl(nav, secNav,''),secNav)">
                      <span :class="{'gsjs_item_title': true, 'active': currentNav == secNav.code}">
                        <img :src="`${secNav.host}${secNav.catalogIcon}`" />
                        <span>{{secNav.name}}</span>
                      </span>
                      <div class="gsjs_item_border">
                        <div class="gsjs_item_border_blue"></div>
                      </div>
                    </router-link>
                  </template>
                  <template v-else>
                    <span :class="{'gsjs_item_title': true, 'active': currentNav == secNav.code}">
                      <span>{{secNav.name}}</span>
                    </span>
                    <div class="gsjs_item_border">
                      <div class="gsjs_item_border_blue"></div>
                    </div>
                  </template>
                  <div class="gsjs_item_content" v-if="secNav.catalogEntityList && secNav.catalogEntityList.length">
                    <template v-for="thirdNav in secNav.catalogEntityList">
                      <router-link :to="redirectUrl(nav, secNav, thirdNav)" :key="thirdNav.id" @click.native="thirdNavLink(thirdNav)">
                        <!-- <a :class="{'item': true, 'subnav-activ': currentSonNav == thirdNav.code}"> -->
                        <a class="item" :class="currentSonNav == thirdNav.code ? 'active':''">
                          <i :class="{'gsjs_item_right': true, 'gsjs_item_right_active': currentSonNav == thirdNav.code}"></i>
                          {{thirdNav.name}}
                        </a>
                      </router-link>
                    </template>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="nav_other">
        <a class="search"></a>
      </div> -->
    </div>
  </div>
</template>
<script>
import "@/assets/css/nav.less";
import BuryingPont from "@/mixins/buryingPoint.js";
import $ from "jquery";
// import { getNavList } from '@/api/nav';
export default {
  name: "Nav",
  mixins: [BuryingPont],
  data() {
    const { path } = this.$route;
    const { id } = this.$route.params;
    let defaultIndex = "-1";
    if (path === "" || path === "/" || path === "/homepage") {
      defaultIndex = "/";
    }
    return {
      activeIndex: id || defaultIndex,
      // 搜索输入框里面的数据
      currentNav: "",
      currentSonNav: ""
    };
  },
  computed: {
    catalogList() {
      return this.$store.state.catalogList;
    }
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler() {
        this.currentNav = "";
        this.currentSonNav = "";

        this.setActiveId();
        // this.chooseNav()
      }
    }
  },
  mounted() {
    // 导航栏鼠标移入
    $("#nav").on('mouseover','.top_nav .nav .nav_item',function(){
      var thisH = $(this).find(".gsjs_move").find(".gsjs_content").innerHeight();
      // $(this).find(".gsjs_move").css("height",thisH+'px');
      $(this).find(".gsjs_move").css({"height":thisH+'px'});
      if(thisH) {
        $(this).find(".gsjs_move").css({'box-shadow': '0px 3px 5px 2px rgba(0, 0, 0, 0.12)'});
        $(this).find(".gsjs_arrow").css('display', 'inline-block');
      }
    
    });
    // 导航栏鼠标移出
    $("#nav").on('mouseout','.top_nav .nav .nav_item',function(){
      // $(this).find(".gsjs_move").css("height",0);
      $(this).find(".gsjs_move").css({ "height":0, 'box-shadow': 'none' });
      $(this).find(".gsjs_arrow").css('display', 'none');
    });


    if($(window).scrollTop()*1>40){
      $("#nav").addClass("on");
    }else{
      $("#nav").removeClass("on");
    }
    $(window).on('scroll', function () {
      var top = $(this).scrollTop() * 1;
      if(top>=40){
        $("#nav").addClass("on");
      }else{
        $("#nav").removeClass("on");
      }
    })


    const { id } = this.$route.params;
    if (id) {
      this.activeIndex = id;
    }
    this.setActiveId();
  },
  methods: {
    logoFn(){
      if(this.$route.path == '/homepage'){
        window.location.reload();
        document.getElementById("app").scrollIntoView();
      }
    },
    setActiveId() {
      const { id } = this.$route.params;

      const { catalogCode, columnId } = this.$route.query;
      if (id) {
        if (id.split("-").length > 3) {
          this.currentSonNav = id;
          this.currentNav = id.split("-").splice(0, 3).join("-");
        } else {
          this.currentNav = id;
        }
      }
      if (catalogCode) {
        if (catalogCode.split("-").length > 3) {
          this.currentSonNav = catalogCode;
          this.currentNav = catalogCode.split("-").splice(0, 3).join("-");
        } else {
          this.currentNav = catalogCode;
        }
      }
      if (columnId) {
        this.activeIndex = columnId;
      }
      if (
        id === undefined &&
        catalogCode === undefined &&
        columnId === undefined
      ) {
        this.activeIndex = "/";
      }
    },
    redirectUrl(rootItem, item, sonItem) {
      if (rootItem.jumpUrl && !item) {
        if (
          !rootItem.catalogEntityList ||
          rootItem.catalogEntityList.length == 0
        ) {
          if (rootItem.jumpUrl !== "/") {
            return `${rootItem.jumpUrl}?columnId=${rootItem.id}`;
          } else {
            return "/";
          }
        } else {
          return "";
        }
      }
      if(rootItem.name == '中介排名') {
        return `/rank/${item.code}?columnId=${
          rootItem.id
        }&subId=${item.id}`;
      }
      if(rootItem.name == 'IPO日历') {
        return `/calendar/${encodeURIComponent(item.code)}?columnId=${
          rootItem.id
        }&subId=${item.id}`
      }
      // if(rootItem.name == '企业中心') {
      //   return `/calendar?columnId=${
      //     rootItem.id
      //   }&subId=${item.id}`
      // }
      // const VIDEO_TYPE = "c7543ae4a7bf419daa8b495bb02e740f";

      // 视频栏目
      // if (item.type === VIDEO_TYPE) {
      //   return `/list/video/${encodeURIComponent(
      //     sonItem ? sonItem.code : item.code
      //   )}?columnId=${rootItem.id}`;
      // }

      // return `/list/article/${encodeURIComponent(
      //   sonItem!='' ? sonItem.code : item.code
      // )}?columnId=${rootItem.id}`;
      if (rootItem.name === '其他服务' && item.name === '上市公司资讯') {
        return `/list/sentiment?columnId=${rootItem.id}`;
      }
      if (rootItem.name === '其他服务') {
        let name = ''
        // if (item.name === '新华传播矩阵') {
        //   name = item.name
        // }
        if (item.name === '新华高端智库') {
          name = sonItem.name
        }
        if (name) {
          return `/list/article?columnId=${rootItem.id}&name=${name}&catalogCode=${sonItem!='' ? sonItem.code : item.code}`;
        }
      }
      return `/list/article?columnId=${rootItem.id}&catalogCode=${sonItem!='' ? sonItem.code : item.code}`;
    },

    firstNavLink(nav){
      this.sensors.track( 'LY_nav_click',{AppName: this.$store.state.AppName, click_content: nav.name, first_level_column: nav.name, page_id:'', is_login: false, platform: this.$store.state.platform})
    },
    flushCom(rootItem,navUrl,secNav){
      // if(rootItem.name == 'IPO日历' && navUrl == this.$route.fullPath){
        // this.$router.go(0)
      // }

      this.sensors.track( 'LY_nav_click',{AppName: this.$store.state.AppName, click_content: secNav.name, second_level_column: secNav.name, page_id:'', is_login: false, platform: this.$store.state.platform})
    },
    thirdNavLink(thirdNav){
      this.sensors.track( 'LY_nav_click',{AppName: this.$store.state.AppName, click_content: thirdNav.name, third_level_column: thirdNav.name, page_id:'', is_login: false, platform: this.$store.state.platform})
    }
  }
};
</script>
<style lang="scss">
</style>
