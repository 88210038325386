import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cookies from 'vue-cookies';
import EchojoyMessage from './components/message/message.js'
import PageTab from './components/pageTab.vue'

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import 'videojs-contrib-hls' //单是 RTMP 的话不需要第三方库，如果是 HLS 的话需要引入videojs-contrib-hls，看具体情况而定。
import 'videojs-flash'
Vue.use(VideoPlayer);

Vue.prototype.$message = EchojoyMessage;

Vue.component(PageTab.name,PageTab);

Vue.config.productionTip = false
Vue.prototype.$cookies = cookies
require("es6-promise").polyfill();
router.afterEach(() => {
  window.scrollTo(0,0);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
