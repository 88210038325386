import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/homepage',
    children: [
    {
      path: 'pages/index/index',
      component: () =>
        import ('@/views/index'),
      name: 'Home'
    },
    {
      path: 'homepage',
      component: () =>
        import ('@/views/homepage'),
      name: 'Homepage'
    },
    {
      path: 'investors',
      component: () =>
        import ('@/views/investors'),
      name: 'Investors'
    },
    {
      path: 'rank/:id',
      component: () =>
        import ('@/views/rank'),
      name: 'rank-list'
    },
    {
      path: 'calendar/:id',
      component: () =>
        import ('@/views/calendar'),
      name: 'calendar'
    },
    {
      path: 'enterprise',
      component: () =>
        import ('@/views/enterprise'),
      name: 'enterprise'
    },
    {
      path: 'person',
      component: () =>
        import ('@/views/person'),
      name: 'Person'
    }
  ]
  },
  {
    path: '/list',
    component: Layout,
    children: [{
        path: 'article',
        component: () =>
          import ('@/views/list/article'),
        name: 'article-list'
      },
      {
        path: 'sentiment',
        component: () =>
          import ('@/views/list/sentiment'),
        name: 'sentiment-list'
      },
      {
        path: 'sentiment-detail',
        component: () =>
          import ('@/views/list/sentiment/detail.vue'),
        name: 'sentiment-detail'
      },
      {
        path: 'video',
        component: () =>
          import ('@/views/list/video'),
        name: 'video-list'
      },
      
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [{
        path: 'pages/luyan-page/luyanDetails',
        component: () =>
          import ('@/views/detail/rold'),
        name: 'rold-detail'
      },
      {
        path: 'pages/other-service/other-service-deatil',
        component: () =>
          import ('@/views/detail/other'),
        name: 'other-detail'
      },
      {
        path: 'enterprise/deatil',
        component: () =>
          import ('@/views/enterprise/detail'),
        name: 'enterprise-detail'
      },
    ]
  },
  {
    path: '/login',
    component: () => import ('@/views/login'),
    name: 'Login'
  },
  {
    path: '/user_agreement',
    component: () => import ('@/views/userAgreement'),
    name: 'user-agreement'
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }

})

export default router