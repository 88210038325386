export default {
  data() {
    return {
      sensors: null
    }
  },
  created () {
    this.sensors = window['sensorsDataAnalytic201505'];
    this.sensors.init({
      server_url: window.LOCAL_CONFIG.BURIED_URL,
      // server_url: 'https://yhqy-occ.test.cnfic.com.cn/project=xinhuacaijing_production', // 生产用_production
      is_track_single_page: true,
      heatmap: {
        scroll_notice_map: 'default',
        clickmap: 'not_collect'
      }
    });
    this.sensors.quick('autoTrack', {
      is_login: false,
      AppName: this.$store.state.AppName,
      platform: this.$store.state.platform,
    });
  }
}