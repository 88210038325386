// import axios from "axios";
export default {
  data () {
    return {
      
    }
  },
  created () {
    // 获取公钥
    // if(!this.$store.state.publicKey){
    //   axios({
    //     method: 'get',
    //     url: window.LOCAL_CONFIG.VUE_APP_BASE_API+'/f/cnfic/xhcj/toC/getKeyApplication?channelNo=XFAP',
    //   }).then(res=>{
    //     var publicKey = '-----BEGIN PUBLIC KEY-----'+res.data.data.Body.PublicKey+'-----END PUBLIC KEY-----';
    //     this.$store.commit('getPublicKey',publicKey);
    //   }).catch(()=>{})
    // }
    // 获取我们服务器的个人信息
    var infoMsg = this.$store.state.localInfoMsg;
    if(this.$store.state.token&&JSON.stringify(infoMsg)=='{}'){
      if(this.$store.state.token.indexOf('BC_') == 0){
        this.$store.dispatch('getBusinessInfo')
      }else{
        this.$store.dispatch('getUserInfo')
      }
    }
  }
}