<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import baseData from "@/mixins/baseData.js";
export default {
  mixins: [baseData],
  created() {
    if(this._isMobile()){
      window.location.href = window.location.href.replace("/ly/#/",'/mly/#/').replace("list/sentiment-detail", "pages/other-service/other-listed-company-detail").replace("/homepage", "/");
    }else{
      // window.location.href = window.location.href.replace("/mly/#/",'/ly/#/');
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style>
html,
body {
  min-height: 100%;
  height: 100%;
}
#app {
  height: 100%;
}
</style>
