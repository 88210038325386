<template>
  <!-- 问题提交异常提示 -->
  <div class="model question_model">
    <div class="feedback_success">
      <div class="feedback_title">提示</div>
      <p class="abnormal-tips">{{ tipTxt }}
        <span class="login-link" v-if="isLink" @click="loginLink">{{loginTxt}}</span>
      </p>
      <span class="feedback_success_button" @click="modelFn">{{isClose?'确定':'关闭'}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tipTxt: '',
      isLink: false,
      loginTxt: '登录个人账户',
      isClose: false,
      isReload: false,
    };
  },
  methods: {
    loginLink(){
      this.closeModel();
      this.$router.push('/login');
    },
    closeModel(){
      this.tipTxt = '';
      this.isLink = false;
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    modelFn(){
      if(this.isClose){
        // if(this.$store.state.token&&this.$store.state.token.indexOf('BC_') == 0) {
        //   this.$store.dispatch('logBusinessOut')
        // } else {
        //   this.$store.dispatch('logToCOut')
        // }
        // if(this.isReload){
        //   window.location.reload()
        // }else{
          this.loginLink();
        // }
      }else{
        this.closeModel();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.model,
.question_model {
  // .transition(all .5s);
  position: fixed;
  // _position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /**遮罩全屏top,left都为0,width,height为100%**/
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // 提交成功
  .feedback_success {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 26px 0 0 0;
    transform: translate(-50%, -50%);
    width: 332px;
    height: 224px;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    .feedback_title {
      line-height: 24px;
      font-size: 24px;
      color: #141825;
    }
    .abnormal-tips {
      margin: 50px 0 59px 0;
      padding: 0 20px;
      font-size: 14px;
      color: #141825;
      .login-link{
        color: #005BAC;
        cursor: pointer;
      }
    }
    // .feedback_success_icon {
    //   width: 80px;
    //   height: 80px;
    //   background: url("../img/side/success.png") no-repeat center;
    //   margin: 48px auto;
    // }
    .feedback_success_button {
      display: inline-block;
      width: 120px;
      height: 40px;
      background: #e5e5e5;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      color: #666666;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
</style>