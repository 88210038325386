import request from "@/utils/request";

export function getNavList(params) {
  return request({
    url: "/f/front/catalog/getAll",
    method: "get",
    params
  });
}
/**
 * 获取友情链接
 */
export function getFriendLinkList() {
  return request({
    url: '/f/front/link/getAll',
  })
}