import Vue from 'vue';
import router from '../../router';
import store from '../../store'
import message from './message.vue';

var instance
var el

var messageConstructor = Vue.extend(message);

var Message = function (options = {}) {
  if (el) {
    return
    // if(document.body.contains(instance.$el)){
    //   document.body.removeChild(el)
    // }
  }
  instance = new messageConstructor({router,store}).$mount(); // 渲染组件
  el = instance.$el
  var close = instance.closeModel
  instance.closeModel = () => {
    close()
    el = undefined
  }
  instance.tipTxt = options.tipTxt;
  instance.isLink = options.isLink;
  instance.loginTxt = options.loginTxt;
  instance.isClose = options.isClose;
  instance.isReload = options.isReload;
  document.body.appendChild(el); // 挂载到 body 下
};
export default Message;