import Vue from "vue";
import "babel-polyfill";
import Vuex from "vuex";
// import { getToken, setToken, removeToken } from '@/utils/auth'
import { getToken, removeToken } from '@/utils/auth'
import { getToCUserInfo, getBusinessInfo, logout, businessLogout } from "@/api/login"
const businessPKIArr = [
  'MQ/g4YS8BBdAf+1+eaSi3',
  'MFwwDQYJKoZIhvcNAQE',
  'WzS/Tqrndb32/0/JiqM7a',
  '+EzAZhCssJJK44Ep0M/G',
  'BBQADSwAwSAJBAJWJmgK',
  'c/uUaAAbLxTfs9R2RECAwEAAQ=='
]
const businessPKI = `${businessPKIArr[1]}${businessPKIArr[4]}${businessPKIArr[0]}${businessPKIArr[3]}${businessPKIArr[2]}${businessPKIArr[5]}`

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: getToken(),
    // psdRegexp: new RegExp(/^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,16}$/),
    psdRegexp: [new RegExp(/[A-Z]/),new RegExp(/[a-z]/),new RegExp(/[0-9]/),new RegExp(/[^\w\s]+/)],
    noPsdRegexp: new RegExp(/[\u4E00-\u9FA5\uF900-\uFA2D\u0020]/),
    baseUrl:window.LOCAL_CONFIG.VUE_APP_BASE_API, 
    friendLinks: [],
    catalogList: [],
    publicKey: '',
    businessPKI: businessPKI, // 企业登录的加密公钥
    localInfoMsg: {},
    AppName: '新华财经路演系统',
    platform: '路演pc',
    // accessToken: 'tjlyappkey:tjlyappsecret', // 测试环境
    accessToken: '21d147fc-63a7-4c2e-ab24-5e4eabec3df4:18524a33-8056-4e97-aa70-13090516f7cb', // 终端二期正式环境，不用切换测试和正式环境
  },
  mutations: {
    SET_FRIEND_LINK(state, data) {
      state.friendLinks = data
    },
  
    SET_CARALOG_LIST(state, data) {
      state.catalogList = data
    },

    SET_TOKEN(state,data){
      state.token = data
    },
    SET_USERINFO(state,data){
      state.localInfoMsg = data
    },

    getPublicKey(state,data){
      state.publicKey = data;
    },

    // changeUserInfo(state,data){
    //   state.userInfoMsg = data;
    // },
    
    // changeLocalInfo(state,data){
    //   state.localInfoMsg = data;
    // }

  },
  actions: {
    setFriendLink({ commit }, data) {
      commit('SET_FRIEND_LINK', data)
    },
  
    setCatalog({ commit }, data) {
      commit('SET_CARALOG_LIST', data)
    },

    getBusinessInfo({ commit }){
      return new Promise((resolve, reject) => {
        getBusinessInfo().then(res => {
          commit('SET_USERINFO', res.data)
          resolve(res.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getUserInfo({ commit },data){
    
      return new Promise((resolve, reject) => {
        getToCUserInfo().then(res => {
          if(res && res.code == 0) {
            commit('SET_USERINFO', res.data)
            resolve(res.data)
          } else if(res.code == -1) {
            data && data.$message({tipTxt:'您的登录信息已过期，请重新登录',isLink:false});
          }else{
            data && data.interfaceBack(res.code,res.msg);
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    logToCOut({commit}){
      return new Promise((resolve, reject) => {
        logout({
          // type: 1,
          // channelNo: 'XFAP'
        }).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', {})
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    logBusinessOut({commit}){
      return new Promise((resolve, reject) => {
        businessLogout({
          type: 1,
        }).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', {})
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    resetToken({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        // commit('SET_ROLES', [])
        removeToken()
        resolve()
      })
    }
  }
});
