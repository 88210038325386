<template>
  <div id="footer">
    <div class="footer">
      <div class="content">
        <!-- 公司信息 -->
        <div class="item item-info">
          <div class="item-info-logo"></div>
          <div class="item-info-details">
            <div class="name">公司名称: 中国经济信息社</div>
            <div class="tel">公司电话: 400-6123115</div>
            <div class="email">公司邮箱: xinhuacaijing@xinhua.org</div>
            <div class="address">
              <div class="address-title">公司地址: </div> 
              <div class="address-info">北京市西城区宣武门外大街甲1号新华社三工作区</div></div>
          </div>
        </div>
        <!-- 友情链接 -->
        <div class="item item-select">
          <div class="item-select-link">友情链接</div>
          <div class="item-select-options">
            <a target="_blank"  v-for="item in list" :key="item.id" :href="item.url" @click="blankLink(item.title,item.url)">{{item.title}}&nbsp;</a>
          </div>
        </div>
        <!-- 二维码 -->
        <div class="item item-ewm">
          <div class="item-ewm-img"></div>
          <div class="itemewm-text">扫码下载移动端</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/footer.less";
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  name: 'Footer',
  mixins: [BuryingPont],
  computed: {
    list() {
      return this.$store.state.friendLinks
    },
  },
  methods:{
    blankLink(title,url){
      // 点击友情链接埋点
      this.sensors.track( 'LY_click_links',{
        AppName: this.$store.state.AppName, 
        click_content: title, 
        content_href: url,
        is_login: false, 
        platform: this.$store.state.platform
      })
    }
  }
};
</script>
<style lang="scss">
</style>
