import request from "@/utils/request";
// 通过token判断是否登录
export function getToCUserInfo(params) {
  return request({
    url: "/f/cnfic/xhcj/auth/getToCUserInfo",
    method: "get",
    params
  });
}
// 获取公钥
export function getKeyApplication(params) {
  return request({
    url: "/f/cnfic/xhcj/toC/getKeyApplication",
    method: "get",
    params
  });
}
// 账号密码登录
export function getUserLogin(data) {
  return request({
    url: "/f/cnfic/xhcj/auth/account/v1/userLogin",
    method: "post",
    data
  });
}
// 获取快捷登录验证码
export function getPhoneCheck(params) {
  return request({
    url: "/f/cnfic/xhcj/auth/base/v1/sms/sending",
    method: "get",
    params
  });
}
// 注册时获取快捷登录验证码
export function regPhoneCheck(params) {
  return request({
    url: "/f/cnfic/xhcj/auth/base/register/sms/sending",
    method: "get",
    params
  });
}
// 企业登录
export function businessLogin(data) {
  return request({
    url: "/f/front/businessCenter/login",
    method: "post",
    data
  });
}
// 短信验证码验证
export function loginByPhone(params) {
  return request({
    url: "/f/cnfic/xhcj/toC/loginByPhone",
    method: "get",
    params
  });
}
// 用户注册校验
export function getUserRegisterCheck(params) {
  return request({
    url: "/f/cnfic/xhcj/auth/userPhoneExist",
    method: "get",
    params
  });
}
// 用户注册
export function getUserRegister(data) {
  return request({
    url: "/f/cnfic/xhcj/auth/account/v1/register/mobile",
    method: "post",
    data
  });
}
// 发送重置密码验证码
export function getSendRestartPwd(params) {
  return request({
    url: "/f/cnfic/xhcj/toC/getSendRestartPwd",
    method: "get",
    params
  });
}
// 重置密码
export function restartPwd(data) {
  return request({
    url: "/f/cnfic/xhcj/auth/account/v1/password/reset/phone",
    method: "post",
    data
  });
}
// token验证
export function checkToken(params) {
  return request({
    url: "/f/cnfic/xhcj/toC/checkToken",
    method: "get",
    params
  });
}
// 获取用户信息
export function getUserInfo(params) {
  return request({
    url: "/f/cnfic/xhcj/toC/getUserInfo",
    method: "get",
    params
  });
}
// 企业登录获取个人信息
export function getBusinessInfo(params) {
  return request({
    url: "/f/front/businessCenter/getBusinessInfo",
    method: "get",
    params
  });
}
// 修改密码
export function updatePwd(data) {
  return request({
    url: "/f/cnfic/xhcj/auth/account/v1/password/modify",
    method: "post",
    data
  });
}
// 登录图形验证码
export function getVerificationCode(params) {
  return request({
    url: "/f/cnfic/common/vertify/code/getVerificationCode",
    method: "get",
    params
  });
}
// 修改用户信息
export function updateUserInfo(data) {
  return request({
    url: "/f/cnfic/xhcj/auth/updateUserNickName",
    method: "post",
    data
  });
}
// 用户退出
export function logout(params) {
  return request({
    url: "/f/cnfic/xhcj/auth/userLogOut",
    method: "get",
    params
  });
}
// 企业用户退出
export function businessLogout(params) {
  return request({
    url: "/f/front/businessCenter/logout",
    method: "get",
    params
  });
}
/**
 * 更新企业账户信息
 * @param {*oldPassword} data 
 * @param {*newPassword} data 
 */
export function uploadBusinessInfo(data) {
  return request({
    url: '/f/front/businessCenter/updatePwd',
    method: 'post',
    data
  })
}