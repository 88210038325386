<template>
  <div class="nav-box">
    <template v-for="(item, index) in subNav">
      <span :key="index">
        <span v-if="index == 0">
          <router-link class="nav-title" to="/">{{item.name}}</router-link>
        </span>
        <span v-else-if="index == subNav.length-1">
          <span>&gt;</span>
          <a class="nav-title" @click="reloadPage">{{item.name}}</a>
        </span>
        <span v-else class="nav-title"><span>&gt;</span>{{ item.name }}</span>
      </span>
    </template>
    <span class="nav-title" v-if="total&&total>0">（共{{ total }}条）</span>
  </div>
</template>
<script>
export default {
  name: 'page-tab',
  props:{
    subNav:{
      type: Array,
      default: function(){
        return []
      }
    },
    total:{
      type: Number,
      default: 0
    }
  },
  methods:{
    reloadPage(){
      this.$router.go(0);
    },
  }
}
</script>