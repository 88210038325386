import Cookies from 'js-cookie'
// const TokenKey = 'ly_token'

const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
  // return 'MTc2MDU5NTQxNzB8WEZBUHwxNjE1NTQxMTQ2NDY4'
  // return 'BC_c5e557b8881f4f0f9a8970b5438a0dbe'
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {secure:true})
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}