import axios from "axios";
import qs from "qs";
import store from '@/store'
import { getToken } from '@/utils/auth'
import message from '@/components/message/message.js';


// import { Message, MessageBox } from "element-ui";
const service = axios.create({
  baseURL: window.LOCAL_CONFIG.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
});
//let loading;
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 设置请求头
    if (store.state.token) {
      config.headers["Authorization"] = getToken();
    }

    if (
      config.headers["Content-Type"] &&
      config.headers["Content-Type"].includes("x-www-form-urlencoded")
    ) {
      if (config.data) config.data = qs.stringify(config.data);  
    } else {
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
      if(config.data) config.data = JSON.stringify(config.data)
    }
    return config;
  },
  error => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // 隐藏loading
    //endLoading();
    const res = response.data;
    // if (res.code !== 0) {
    //   switch(res.code){
    //     case 601:return loginOverTime();
    //     case -1: // 登录时账号密码错误会返回-1
    //     case 306: // 登录时验证码错误
    //     case 3103: // 投资者问答提问接口，如果公司不存在会返回这个code
    //     case 303: // 企业回复时，对应的提问已经删除了
    //     case 200:
    //     case 2008:
    //     case 2007:return res;
    //   }
    //   return Promise.reject(new Error(res.msg || '系统异常'))
    // } else {
    //   return res;
    // }
    if(res.code == 601 || res.code == 401){
      loginOverTime()
      return Promise.reject(new Error(res.msg || '系统异常'));
    }else{
      return res;
    }
  },
  error => {
    // eslint-disable-next-line no-console
    console.log("err" + error); // for debug
    //endLoading();
    if (error.response) {
      let code = error.response.data.code;
      console.log(code);
      switch (code) {
        case "402":
          return loginOverTime();
      }
      // Message({
      //   message: "系统异常",
      //   type: "error",
      //   duration: 2 * 1000
      // });
    } else {
      // Message({
      //   message: "连接超时",
      //   type: "error",
      //   duration: 2 * 1000
      // });
    }
    return Promise.reject(error);
  }
);
function loginOverTime() {
  
  store.dispatch('resetToken').then(() => {
    // location.reload()
    message({tipTxt:'您的登录信息已过期，请',isLink:true,loginTxt:'重新登录',isClose:true});//,isReload:true
  })
}
export default service;
